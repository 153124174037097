import { h, Fragment } from "preact"
import style from "./Header.sass";
import { isNequiThirdPartyCheckout } from '../../utils/helpers'
import PricePanel from "../PricePanel/PricePanel"
import PropTypes from "prop-types";

export default function Header({ store }) {
  const { $state } = store;
  const { transaction, thirdPartyCheckout } = $state;
  const { merchant } = transaction;

  if (!merchant) {
    return null;
  }

  return (
    <Fragment>
      <div className={style.header}>
        <div className={style.logoWrapper}>
          {merchant.logoUrl && (
            <div
              style={{ backgroundImage: `url(${merchant.logoUrl})` }}
              className={`${style.logo} force-printable`}
            />
          )}
          <strong className={style.textBreak}>
            <span
              className={`${
                isNequiThirdPartyCheckout(thirdPartyCheckout)
                  ? style["title-nequi"]
                  : ""
              }`}
            >
              Pago a
            </span>
            &nbsp;
            {merchant.name}
          </strong>
        </div>
      </div>
      <div className={style.merchantContainer}>
        {transaction && <PricePanel store={store} />}
      </div>
    </Fragment>
  );
}

Header.propTypes = {
  store: PropTypes.shape({
    $state: PropTypes.shape({
      transaction: PropTypes.shape({
        merchant: PropTypes.shape({
          logoUrl: PropTypes.string,
          name: PropTypes.string
        })
      }),
      thirdPartyCheckout: PropTypes.string
    })
  })
}
