import { h, Component } from "preact";
import style from "./Result.scss";
import logoPSE from "../../assets/original-icons/pse.png";
import logoBANCOLOMBIA_TRANSFER from "../../assets/original-icons/bancolombia-transfer.svg";
import Header from "../Header/Header";
import PCOLSummaryBox from "../PCOLSummaryBox/PCOLSummaryBox";
import RetryTime from "../RetryTime/RetryTime";
import BNPLSummary from "../BNPLSummary/BNPLSummary";
import DaviplataSummary from "../DaviplataSummary/DaviplataSummary";
import SuPlusPaySummary from "../SuPlusPaySummary/SuPlusPaySummary";
import { hotjarDispatch, isNequiThirdPartyCheckout, validateRetry } from "../../utils/helpers";
import { RetryMessage } from "../RetryMessage/RetryMessage";
import { InfoCurrentView } from "../InfoCurrentView/InfoCurrentView";
import { TransactionInfo } from "../TransactionInfo/TransactionInfo";
import { FieldsTransaction } from "../FieldsTransaction/FieldsTransaction";
import { pseStatusDict } from "../../utils/types";
import PropTypes from "prop-types";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownFinished: false,
    };

    this.handleCountdownFinished = this.handleCountdownFinished.bind(this);
    this.retryRedirect = this.retryRedirect.bind(this);
    this.finalRedirect = this.finalRedirect.bind(this);
    this.print = this.print.bind(this);
  }

  handleCountdownFinished() {
    this.setState({ countdownFinished: true });
  }

  retryRedirect = () => {
      const { store } = this.props
      const { $state } = store
      const { transaction } = $state
      const checkoutUrl = String(process.env.CHECKOUT_URL)
      
      let redirectUrl = `${checkoutUrl}/retry_transaction/${transaction.id}?env=${this.state.env}`
  
      if (!transaction?.paymentLinkId && transaction?.signatureIntegrity) {
        redirectUrl += `&signature=${transaction?.signatureIntegrity}`
      }
      window.location.href = redirectUrl;
    }

  finalRedirect = () => {
    const { store } = this.props
    const { $state } = store
    store.$actions.redirectWithTransaction($state.transaction.redirectUrl, $state.transaction.id, this.state.env)
  }

  print = () => {
    window.print()
  }

  hotjarRun(validateRetryValue, countdownFinishedValue) {
    if (validateRetryValue && countdownFinishedValue) {
      hotjarDispatch({ type: "event", action: "checkout_retry" });
    }
  }

  render() {
    const { store } = this.props;
    const { $state } = store;
    const { transaction, thirdPartyCheckout } = $state;
    const {
      merchant,
      paymentMethod,
      paymentMethodType,
      reference,
      status,
      redirectUrl,
      parentTransaction,
    } = transaction;

    const pseStatus = pseStatusDict[status];
    const statusClass = status.toLowerCase();

    let accumulatedPointsAmount = 0;
    const PCOL_REFERENCE_CUMULATIVE_POINTS = 900;
    if (
      paymentMethodType === "PSE" &&
      parentTransaction?.paymentMethod?.extra?.remainingAmountInCents
    ) {
      accumulatedPointsAmount = parseInt(
        (1 * (parentTransaction.paymentMethod.extra.remainingAmountInCents / 100)) /
          PCOL_REFERENCE_CUMULATIVE_POINTS,
      );
    }

    this.hotjarRun(validateRetry(transaction), !this.state.countdownFinished);

    const rowClass = isNequiThirdPartyCheckout(thirdPartyCheckout) ? style["row-nequi"] : "";
    const extraTdClass = isNequiThirdPartyCheckout(thirdPartyCheckout)
      ? style["table-data-nequi"] + " " + style["subSection-nequi"]
      : "";
    const tdClass = style.subSection + " " + style[statusClass] + " " + extraTdClass;

    return (
      <div class={style.confirmationWrapper}>
        {merchant && (
          <div className={style.merchantSection}>
            <Header store={store} />
          </div>
        )}
        <div className={style.contentSection}>
          <div className={style.contentContainer}>
            {!validateRetry(transaction) && (
              <InfoCurrentView transaction={transaction} store={store}/>
            )}

            {(status === "ERROR" || status === "DECLINED") &&
              parentTransaction?.paymentMethod?.extra?.pointsRedeemed && (
                <span>
                  Se te reestableceran tus{" "}
                  {parentTransaction.paymentMethod.extra.pointsRedeemed || ""}{" "}
                  Puntos Colombia en las próximas horas
                </span>
              )}

            {status === "PENDING" && (
              <div>
                <div class={style.iconWrapper}>
                  <div class={`${style.icon} ${style.pending}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"
                      />
                    </svg>
                  </div>
                </div>
                <div class={style.transactionSummary}>
                  {paymentMethodType !== "PSE" &&
                    paymentMethodType !== "BANCOLOMBIA_BNPL" && (
                      <div>
                        <strong>Transacción en verificación</strong>
                        <p>
                          Tu transacción está tomando más tiempo de lo normal para
                          ser verificada. Pronto recibirás un email con el
                          resultado final de la misma.&nbsp;
                          {parentTransaction &&
                            "Si la transacción finaliza de manera exitosa, se notificará al comercio, en caso de que resulte fallida se te reestableceran tus Puntos Colombia en las próximas horas. "}
                        </p>
                      </div>
                    )}
                  {paymentMethodType === "PSE" && (
                    <div>
                      <strong>Transacción pendiente</strong>
                      <p>
                        En este momento tu transacción (ref.{" "}
                        <strong>{reference}</strong>) presenta un proceso de pago
                        cuya verificación&nbsp; se encuentra{" "}
                        <strong>PENDIENTE</strong> de recibir confirmación por
                        parte de la entidad financiera.&nbsp; Por favor espera
                        unos minutos y vuelve a consultar más tarde para verificar
                        si tu pago fue confirmado de forma exitosa.&nbsp;
                        {parentTransaction &&
                          "Si la transacción finaliza de manera exitosa, se notificará al comercio, en caso de que resulte fallida se te reestableceran tus Puntos Colombia en las próximas horas. "}
                        Si deseas mayor información sobre el estado actual de tu
                        operación puedes comunicarte con{" "}
                        <strong>{merchant.name}</strong> escribiendo a&nbsp;{" "}
                        <strong>
                          <a href={`mailto:${merchant.email}`}>
                            {merchant.email}
                          </a>
                        </strong>{" "}
                        y preguntar por el estado de la transacción{" "}
                        <strong>#{paymentMethod.extra.traceabilityCode}</strong>
                      </p>
                    </div>
                  )}
                  {paymentMethodType === "BANCOLOMBIA_BNPL" && (
                    <div className={style.summary}>
                      <strong>
                        Entendemos que esperar puede resultar incómodo, pero
                        necesitamos este tiempo para procesar tu pago y completar
                        la compra.
                      </strong>
                      <p>
                        <ul>
                          <li>
                            Si después de unos minutos sigues viendo este mensaje,
                            te recomendamos recargar la página.
                          </li>
                          <li>
                            Además, recibirás información sobre el estado final de
                            la transacción en tu correo registrado.
                          </li>
                        </ul>
                      </p>
                    </div>
                  )}
                  <br />
                  <div
                    className={style.referencePanel}
                    hidden={paymentMethodType === "BANCOLOMBIA_BNPL"}
                  >
                    <div>
                      Referencia: <strong>{reference}</strong>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {status === "APPROVED" &&
              parentTransaction?.paymentMethod?.extra && (
                <PCOLSummaryBox
                  redeemedPoints={
                    parentTransaction.paymentMethod.extra.pointsRedeemed || 0
                  }
                  moneyAmount={
                    parentTransaction.paymentMethod.extra.remainingAmountInCents || 0
                  }
                  accumulatedPoints={accumulatedPointsAmount}
                />
              )}

            {parentTransaction && (
              <TransactionInfo
                transaction={parentTransaction}
                isTransactionPCO={true}
                statusClass={statusClass}
                thirdPartyCheckout={thirdPartyCheckout}
              />
            )}

            {validateRetry(transaction) && (
              <div className={style.orderOne}>
                <RetryMessage useNequiStyle={isNequiThirdPartyCheckout(thirdPartyCheckout)}/>
                {validateRetry(transaction) && (
                  <RetryTime
                    store={store}
                    onCountdownFinished={this.handleCountdownFinished}
                  />
                )}
              </div>
            )}

            <TransactionInfo
              transaction={transaction}
              isTransactionPCO={false}
              statusClass={statusClass}
              thirdPartyCheckout={thirdPartyCheckout}
            />

            {paymentMethodType === "BANCOLOMBIA_TRANSFER" &&
              paymentMethod?.extra &&
              !validateRetry(transaction) && (
                <div class={style.asyncPaymentMethodWrapper}>
                  <div class={style.paidWith}>Pago efectuado con</div>
                  <img
                    alt={paymentMethodType}
                    src={logoBANCOLOMBIA_TRANSFER}
                    class={style.theBancolombiaLogo}
                  />
                  <table className={style.transactionInfo} cellPadding="5">
                    <tbody>
                    <tr className={rowClass}>
                        <td className={tdClass.trim()} colSpan="2">
                          Comprobante de pago Botón Bancolombia
                        </td>
                      </tr>
                      <FieldsTransaction
                        thirdPartyCheckout={thirdPartyCheckout}
                        transaction={transaction}
                        extraValue={pseStatus}
                      />
                    </tbody>
                  </table>
                </div>
              )}

            {paymentMethodType === "PSE" &&
              paymentMethod?.extra &&
              !validateRetry(transaction) && (
                <div class={style.asyncPaymentMethodWrapper}>
                  <div class={style.paidWith}>Pago efectuado con</div>
                  <img src={logoPSE} class={style.theLogo} alt="Logo PSE" />
                  <table className={style.transactionInfo} cellPadding="5">
                    <tbody>
                    <tr className={rowClass}>
                      <td className={tdClass.trim()} colSpan="2">
                          Comprobante de pago PSE
                        </td>
                      </tr>
                      <FieldsTransaction
                        thirdPartyCheckout={thirdPartyCheckout}
                        transaction={transaction}
                        extraValue={pseStatus}
                      />
                    </tbody>
                  </table>
                </div>
              )}

            {paymentMethodType === "BANCOLOMBIA_BNPL" &&
              paymentMethod?.extra &&
              !validateRetry(transaction) && (
                <BNPLSummary
                  transaction={transaction}
                  thirdPartyCheckout={thirdPartyCheckout}
                />
              )}
            {paymentMethodType === "DAVIPLATA" &&
              paymentMethod?.extra &&
              !validateRetry(transaction) && (
                <DaviplataSummary
                  transaction={transaction}
                  thirdPartyCheckout={thirdPartyCheckout}
                />
              )}
            {paymentMethodType === "SU_PLUS" &&
              paymentMethod?.extra &&
              !validateRetry(transaction) && (
                <SuPlusPaySummary
                  transaction={transaction}
                  thirdPartyCheckout={thirdPartyCheckout}
                />
              )}
            <div
              className={`${
                validateRetry(transaction) ? style.orderOne : style.orderThree
              }`}
            >
              <div class={`${style.redirectButtons} ${style.hideForPrinting}`}>
                {validateRetry(transaction) && (
                  <button
                    className={`button-primary ${style.hideForPrinting} ${style.retryButton}`}
                    onClick={this.retryRedirect}
                  >
                    Intenta pagar nuevamente
                  </button>
                )}
                {redirectUrl && (
                  <button
                    className={`button-primary ${style.hideForPrinting}`}
                    onClick={this.finalRedirect}
                  >
                    Regresar al comercio
                  </button>
                )}
              </div>
              <button
                className={`${style.printButton} ${style.hideForPrinting} ${isNequiThirdPartyCheckout(thirdPartyCheckout) ? style["printButton-nequi"] : ""}`}
                onClick={this.print}
                onKeyDown={this.print}
              >
                Imprimir comprobante
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Result.propTypes = {
  store: PropTypes.shape({
    $actions: PropTypes.shape({
      redirectWithTransaction: PropTypes.func,
    }),
    $state: PropTypes.shape({
      transaction: PropTypes.shape({
        id: PropTypes.string,
        paymentLinkId: PropTypes.string,
        paymentMethodType: PropTypes.string,
        signatureIntegrity: PropTypes.string,
        redirectUrl: PropTypes.string,
        status: PropTypes.string,
        merchant: PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
        }),
        amountInCents: PropTypes.number,
        reference: PropTypes.string,
        paymentMethod: PropTypes.shape({
          extra: PropTypes.shape({
            traceabilityCode: PropTypes.string,
            pointsRedeemed: PropTypes.number,
            remainingAmountInCents: PropTypes.number,
          }),
        }),
        parentTransaction: PropTypes.shape({
          paymentMethod: PropTypes.shape({
            extra: PropTypes.shape({
              traceabilityCode: PropTypes.string,
              pointsRedeemed: PropTypes.number,
              remainingAmountInCents: PropTypes.number,
            }),
          }),
        }),
      }),
      thirdPartyCheckout: PropTypes.string,
    }),
  }),
};

export default Result;
