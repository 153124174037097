import { h } from "preact"
import style from "./InfoCurrentView.scss";
import PropTypes from "prop-types";

const paymentMethodLocales = {
  CARD: "Tarjetas",
  NEQUI: "Nequi",
  PSE: "PSE",
  BANCOLOMBIA_TRANSFER: "Transferencia Bancolombia",
  BANCOLOMBIA_QR: "QR Bancolombia",
  BANCOLOMBIA_COLLECT: "Pago en efectivo",
  BANCOLOMBIA: "TokenBox Bancolombia",
  BANCOLOMBIA_BNPL: "Compra y Paga Después Bancolombia",
  PCOL: "Puntos Colombia",
  DAVIPLATA: "Daviplata",
  SU_PLUS: "SU+ Pay",
};

export const InfoCurrentView = ({ transaction, store }) => {
  const { status, paymentMethod } = transaction;
  const isSandbox = store.$actions.isSandbox();
  let localeStatus = "";

  if (status === "APPROVED") {
    localeStatus = "Transacción aprobada";
  } else if (status === "DECLINED") {
    localeStatus = "Transacción declinada";
  } else if (status === "ERROR") {
    localeStatus = "Transacción con error";
  } else if (status === "VOIDED") {
    localeStatus = "Transacción anulada";
  } else if (status === "PENDING" && paymentMethod.type === "BANCOLOMBIA_BNPL") {
    localeStatus = "Transacción en verificación";
  } else if (status === "PENDING") {
    localeStatus = "Transacción pendiente";
  }

  localeStatus += ` (${paymentMethodLocales[paymentMethod.type]})`;

  return (
    <div
      key={localeStatus}
      className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ""} ${style.centered}`}
    >
      <div className={style.iconWrapper}>
        {status === "APPROVED" && (
          <div className={`${style.icon} ${style.approved}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
              <circle cx="27.9989" cy="28.0003" r="19.6" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.9998 4.66669C15.1132 4.66669 4.6665 15.1134 4.6665 28C4.6665 40.8867 15.1132 51.3334 27.9998 51.3334C40.8865
      51.3334 51.3332 40.8867 51.3332 28C51.3332 15.1134 40.8865 4.66669 27.9998 4.66669ZM42.8935 19.4167L41.1062 17.9167L24.2968
      37.9424L14.8229 28.5124L13.1768 30.1662L24.4528 41.3896L42.8935 19.4167Z"
              />
            </svg>
          </div>
        )}
        {(status === "ERROR" || status === "DECLINED") && (
          <div className={`${style.icon} ${style.error}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
              <circle cx="28.0006" cy="27.9999" r="22.4" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.0003 4.66669C15.1137 4.66669 4.66699 15.1134 4.66699 28C4.66699 40.8867 15.1137 51.3334 28.0003 51.3334C40.887
          51.3334 51.3337 40.8867 51.3337 28C51.3337 15.1134 40.887 4.66669 28.0003 4.66669ZM39.667 17.7788L38.2215 16.3334L28.0003 26.5545L17.7792
          16.3334L16.3337 17.7788L26.5548 28L16.3337 38.2212L17.7792 39.6667L28.0003 29.4455L38.2215 39.6667L39.667 38.2212L29.4458 28L39.667 17.7788Z"
              />
            </svg>
          </div>
        )}
      </div>
      <span className={style.title}>{localeStatus}</span>
    </div>
  );
};

InfoCurrentView.propTypes = {
  transaction: PropTypes.shape({
    status: PropTypes.string.isRequired,
    paymentMethod: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  store: PropTypes.object.isRequired,
};
