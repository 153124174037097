export default {
  loaders: {},
  // 
  mode: 'widget',
  merchant: {},
  transaction: {},
  method: {},
  creditCardInfo: {},
  paymentLink: null,
  publicKey: null,
  financialInstitutions: null,
  env: null,
  thirdPartyCheckout: null,
}
