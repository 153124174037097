import { h } from "preact";
import style from './SecureTransferFooter.sass';
import wompiLogo from '../../assets/product_logo.svg';
import nequiLogo from '../../assets/nequi_footer_logo.svg';
import financialInstitutionLogo from '../../assets/financial-institution.png';
import PropTypes from "prop-types";

export default function SecureTransferFooter({ isNequiCheckout }) {
  return (
    <div className={style.container}>
      <div>
        <img className={`${style.financialInstitution} ${style.desktop}`} src={financialInstitutionLogo} />
        <div className={style.text}>Pagos seguros por</div>
        <img
          className={style.logo}
          src={isNequiCheckout ? nequiLogo : wompiLogo}
          alt={isNequiCheckout ? 'Nequi' : 'Wompi'}
        />
      </div>
    </div>
  );
}

SecureTransferFooter.propTypes = {
  isNequiCheckout: PropTypes.bool,
};
