import { h } from "preact";
import style from "./Loading.scss";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import { isNequiThirdPartyCheckout } from "../../utils/helpers";
import PropTypes from "prop-types";

export const Loading = ({ isSandbox, error, thirdPartyCheckout }) => {
  return (
    <div className={style.contentSection}>
      {error === null && (
        <div className={style.contentContainer} style="text-align: center;">
          <div className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ""}`}>
            <span className={style.title}>Confirmando tu pago</span>
          </div>
          <div className="loader-spinner">
            <LoaderSpinner useNequiSpinner={isNequiThirdPartyCheckout(thirdPartyCheckout)} />
          </div>
          <p className="long-polling-text">
            Espera unos momentos mientras <strong>procesamos tu transacción</strong>.
          </p>
        </div>
      )}
      {typeof error === "string" && (
        <div className={style.contentContainer}>
          <div className={`${style.viewTitle} ${isSandbox ? style.isSandbox : ""}`}>
            <div className={style.iconWrapper}>
              <div className={`${style.icon} ${style.error}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
                  <circle cx="28.0006" cy="27.9999" r="22.4" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.0003 4.66669C15.1137 4.66669 4.66699 15.1134 4.66699 28C4.66699 40.8867 15.1137 51.3334 28.0003 51.3334C40.887 51.3334 51.3337 40.8867 51.3337 28C51.3337 15.1134 40.887 4.66669 28.0003 4.66669ZM39.667 17.7788L38.2215 16.3334L28.0003 26.5545L17.7792 16.3334L16.3337 17.7788L26.5548 28L16.3337 38.2212L17.7792 39.6667L28.0003 29.4455L38.2215 39.6667L39.667 38.2212L29.4458 28L39.667 17.7788Z"
                  />
                </svg>
              </div>
            </div>
            <span className={style.title}>Ocurrió un error</span>
          </div>
          <p>
            <strong>{error}</strong>
          </p>
        </div>
      )}
    </div>
  );
};

Loading.propTypes = {
  isSandbox: PropTypes.bool,
  error: PropTypes.string,
  thirdPartyCheckout: PropTypes.string,
}

export default Loading;
