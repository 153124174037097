// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import PropTypes from 'prop-types';
import style from './BNPLSummary.sass';
import logoBnpl from '../../assets/original-icons/bancolombia-bnpl.svg';
import PaymentSummaryCommon from '../PaymentSummaryCommon/PaymentSummaryCommon';

const BNPLSummary = ({ transaction, thirdPartyCheckout }) => {
  return (
    <PaymentSummaryCommon
      transaction={transaction}
      thirdPartyCheckout={thirdPartyCheckout}
      methodLabel="Comprobante de pago Compra y Paga Después Bancolombia"
      logoSrc={logoBnpl}
      altText="BNPL"
      wrapperClassName={style.bnplPaymentMethodWrapper}
      logoClassName={style.theBnplBancolombiaLogo}
    />
  );
};

BNPLSummary.propTypes = {
  transaction: PropTypes.object.isRequired,
  thirdPartyCheckout: PropTypes.any,
};

export default BNPLSummary;
