import { h, Component, Fragment } from "preact";
import { formatPrice } from "../Formatter/Formatter";
import style from "./TransactionInfo.scss";
import logoBnpl from "../../assets/original-icons/bancolombia-bnpl.svg";
import logoSuPlusPay from "../../assets/original-icons/su-plus-pay-logo.svg";
import logoDaviplata from "../../assets/original-icons/daviplata-logo.svg";
import { isNequiThirdPartyCheckout, validateRetry } from "../../utils/helpers";
import { pseStatusDict } from "../../utils/types";
import logoPSE from '../../assets/original-icons/pse.png';
import logoBANCOLOMBIA_TRANSFER from '../../assets/original-icons/bancolombia-transfer.svg';
import { FieldsTransaction } from "../FieldsTransaction/FieldsTransaction";
import PropTypes from "prop-types";

const paymentMethodsLogo = {
  PSE: { logo: logoPSE, style: "theLogo" },
  BANCOLOMBIA_BNPL: { logo: logoBnpl, style: "theBnplBancolombiaLogo" },
  BANCOLOMBIA_TRANSFER: {
    logo: logoBANCOLOMBIA_TRANSFER,
    style: "theBancolombiaLogo",
  },
  SU_PLUS: { logo: logoSuPlusPay, style: "theSuPlusPayLogo" },
  DAVIPLATA: { logo: logoDaviplata, style: "theDaviplataLogo" },
};

export const InfoRow = ({ label, value, isNequi, colSpan = 1, customClass = "" }) => {
  if (colSpan === 2) {
    const rowClass = isNequi ? style["row-nequi"] : "";
    const additionalClass = isNequi
      ? style["table-data-nequi"] + " " + style["subSection-nequi"]
      : "";
    const tdClass = (customClass + " " + additionalClass).trim();

    return (
      <tr className={rowClass}>
        <td className={tdClass} colSpan="2">
          {value}
        </td>
      </tr>
    );
  }

  const rowClass = isNequi ? style["row-nequi"] : "";
  const leftAdditionalClass = isNequi
    ? style["table-data-nequi"] + " " + style["property-nequi"]
    : "";
  const leftClass = (style.property + " " + leftAdditionalClass).trim();
  const rightClass = isNequi
    ? (style["table-data-nequi"] + " " + style["value-nequi"]).trim()
    : "";

  return (
    <tr className={rowClass}>
      <td className={leftClass}>
        {label}
      </td>
      <td className={rightClass}>
        {value}
      </td>
    </tr>
  );
};

export class TransactionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBodyRetryEnabled: false,
    };
    this.bodyRetryRef = null;
  }

  renderRetryInfo({ thirdPartyCheckout, transaction, isNequi, transactionInfoProps }) {
    const enabledBodyRetry = () => {
      if (this.bodyRetryRef) {
        const panel = this.bodyRetryRef;
        panel.style.maxHeight = panel.style.maxHeight ? null : panel.scrollHeight + "px";
        this.setState((prevState) => ({
          isBodyRetryEnabled: !prevState.isBodyRetryEnabled,
        }));
      }
    };

    const renderRowForProp = ({ key, label }) => {
      const value =
        transaction[key] || (key === "externalIdentifier" ? transaction.paymentMethod?.extra?.externalIdentifier : null);
      return value ? <InfoRow key={key} label={label} value={value} isNequi={isNequi} /> : null;
    };

    return (
      <div
        className={`${style.infoTransactionRetry} ${
          !validateRetry(transaction) ? style.orderThree : style.orderFour
        }`}
      >
        <div className={style.paidWith}>Pago efectuado con</div>
        <img
          alt={transaction.paymentMethodType}
          src={paymentMethodsLogo[transaction.paymentMethodType].logo}
          className={style[paymentMethodsLogo[transaction.paymentMethodType].style]}
        />
        <table className={style.transactionInfo}>
          <thead>
            <tr className={isNequi ? style["row-nequi"] : ""}>
              <th colSpan="2">
                <button
                  type="button"
                  onClick={enabledBodyRetry}
                  onKeyDown={enabledBodyRetry}
                  className={`${style.canRetryTitle} ${
                    this.state.isBodyRetryEnabled ? style.active : ""
                  } ${isNequi ? style["table-data-nequi"] : ""}`}
                >
                  Comprobante de pago
                </button>
              </th>
            </tr>
          </thead>
          <tbody ref={(ref) => (this.bodyRetryRef = ref)} className={style.bodyRetry}>
            {transactionInfoProps.map(renderRowForProp)}
            <FieldsTransaction
              thirdPartyCheckout={thirdPartyCheckout}
              transaction={transaction}
              extraValue={pseStatusDict[transaction.status]}
            />
          </tbody>
        </table>
      </div>
    );
  }

  renderRegularInfo({ transaction, isNequi, transactionInfoProps, titleTable, taxesTypesProps }) {
    return (
      <table className={style.transactionInfo}>
        <tbody>
          <tr className={isNequi ? style["row-nequi"] : ""}>
            <td
              className={`${style.subSection} ${style[this.props.statusClass]} ${
                isNequi ? `${style["table-data-nequi"]} ${style["subSection-nequi"]}` : ""
              }`}
              colSpan="2"
            >
              {titleTable}
            </td>
          </tr>
          {transactionInfoProps.map(({ key, label }) => {
            const value =
              key === "externalIdentifier"
                ? transaction.paymentMethod?.extra?.externalIdentifier
                : transaction[key];
            return <InfoRow key={key} label={label} value={value} isNequi={isNequi} />;
          })}
          {transaction?.taxes?.length > 0 && (
            <InfoRow colSpan={2} customClass={style.subSection} value="Información de impuestos" isNequi={isNequi} />
          )}
          {transaction?.taxes?.map((tax) => (
              <Fragment key={tax.type}>
                <InfoRow label="Tipo" value={taxesTypesProps[tax.type]} isNequi={isNequi} />
                <InfoRow label="Monto" value={formatPrice(tax.amountInCents)} isNequi={isNequi} />
              </Fragment>
            ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { transaction, isTransactionPCO, thirdPartyCheckout } = this.props;
    const isNequi = isNequiThirdPartyCheckout(thirdPartyCheckout);

    const transactionInfoProps = [{ key: "id", label: "Transacción #" }];
    if (transaction.paymentMethod?.extra?.externalIdentifier && transaction.paymentMethodType === "DAVIPLATA") {
      transactionInfoProps.push({ key: "externalIdentifier", label: "Número de aprobación" });
    }
    transactionInfoProps.push({ key: "reference", label: "Referencia" });

    const taxesTypesProps = {
      VAT: "IVA",
      CONSUMPTION: "Impuesto al consumo",
    };

    const titleTable = isTransactionPCO
      ? "Información de la transacción con Puntos Colombia"
      : "Información de la transacción";

    if (validateRetry(transaction)) {
      return this.renderRetryInfo({ thirdPartyCheckout, transaction, isNequi, transactionInfoProps });
    }

    return <>{this.renderRegularInfo({ transaction, isNequi, transactionInfoProps, titleTable, taxesTypesProps })}</>;
  }
}

InfoRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isNequi: PropTypes.bool,
  colSpan: PropTypes.number,
  customClass: PropTypes.string,
};

TransactionInfo.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    paymentMethodType: PropTypes.string,
    paymentMethod: PropTypes.shape({
      extra: PropTypes.shape({
        externalIdentifier: PropTypes.string,
      }),
    }),
    reference: PropTypes.string,
    taxes: PropTypes.array,
  }),
  isTransactionPCO: PropTypes.bool,
  thirdPartyCheckout: PropTypes.string,
  statusClass: PropTypes.string,
}
