import React from 'react'
import style from './LoaderSpinner.sass'
import PropTypes from "prop-types";

export default function LoaderSpinner ( props ) {
  return (
    <div className={`
      ${style.loaderSpinner}
      ${props.useNequiSpinner && style["loaderSpinner-nequi"]}`} />
  )
}

LoaderSpinner.propTypes = {
  useNequiSpinner: PropTypes.bool
}
