export default {
  tokenizeCreditCard ( api, data ) {
    return api.post('/tokens', data)
  },
  getMerchantByPublicKey ( api, publicKey ) {
    return api.get('/merchants/' + publicKey)
  },
  createTransaction ( api, data ) {
    return api.post('/transactions', data)
  },
  getTransactionByNumber ( api, number ) {
    return api.get('/transactions/' + number)
  },
  getPaymentLinkById ( api, uid ) {
    return api.get('/payment_links/' + uid)
  },
  allFinancialInstitutions ( api ) {
    return api.get('/pse/financial_institutions')
  },
  getSessionTime(api) {
    return api.get('/widget/time')
  },
  getTransactionRetry(api, transactionId) {
    return api.get('/transactions/' + transactionId + '/retry', true)
  },
  getSmartCheckoutConfig(api, publicKey) {
    return api.get('/global_settings/' + publicKey + '/checkout_intelligence', true)
  },
  getRetryTransactionConfig(api, publicKey) {
    return api.get('/global_settings/' + publicKey + '/retry_transaction_config', true)
  },
  getFeatureFlagEvaluation(api, publicKey, feature) {
    return api.get(`/feature_flags/${publicKey}?feature=${feature}`)
  },
}
