export const THIRD_PARTY_CHECKOUTS = Object.freeze({
    NEQUI: 'nequi',
})

export const pseStatusDict = {
    'PENDING': 'Pendiente',
    'ERROR': 'Fallida',
    'DECLINED': 'Rechazada',
    'APPROVED': 'Aprobada',
  }
