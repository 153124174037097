import style from "./FieldsTransaction.scss";
import PropTypes from "prop-types";

export const TransactionRow = ({ label, value, thirdPartyCheckout }) => {
  const rowClass = thirdPartyCheckout ? style["row-nequi"] : "";
  const additionalLeftClasses = thirdPartyCheckout 
    ? `${style["table-data-nequi"]} ${style["property-nequi"]}` 
    : "";
  const leftClass = `${style.property} ${additionalLeftClasses}`;
  const rightClass = thirdPartyCheckout 
    ? `${style["table-data-nequi"]} ${style["value-nequi"]}` 
    : "";
    
  return (
    <tr className={rowClass}>
      <td className={leftClass}>{label}</td>
      <td className={rightClass}>{value}</td>
    </tr>
  );
};

TransactionRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  thirdPartyCheckout: PropTypes.string,
};
