import { THIRD_PARTY_CHECKOUTS } from "./types"

export function hotjarDispatch({ type, action }) {
  try {
    window.hj = window.hj || []

    if (typeof window.hj === "function") {
      window.hj(type, action)
    }
  } catch (error) {
    console.log(error)
  }
}

export function isNequiThirdPartyCheckout(thirdPartyCheckout) {
  return thirdPartyCheckout === THIRD_PARTY_CHECKOUTS.NEQUI
}

export function validateRetry(transaction) {
  return (
    transaction?.canRetry &&
    [null, undefined].includes(
      transaction.paymentMethod?.extra?.parentTransactionId,
    ) &&
    [null, undefined].includes(
      transaction.paymentMethod?.extra?.childTransactionId,
    ) &&
    !this.state.countdownFinished
  );
}
