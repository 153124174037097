import { Fragment } from "preact";
import { isNequiThirdPartyCheckout } from "../../utils/helpers";
import { pseStatusDict } from "../../utils/types";
import Documents from "../Utils/Documents.json";
import PropTypes from "prop-types";
import { TransactionRow } from "./TransactionRow";

export const FieldsTransaction = ({ thirdPartyCheckout, transaction, extraValue = null }) => {
  const isNequi = isNequiThirdPartyCheckout(thirdPartyCheckout);
  const merchantInfo = `${transaction?.merchant?.name} - (${transaction?.merchant?.legalName} ${transaction?.merchant?.legalIdType} ${transaction?.merchant?.legalId})`;

  const getPayerType = () => {
    const userType = transaction?.paymentMethod?.userType;
    if (userType === "PERSON") {
      return "Persona Natural";
    } else if (userType === "COMPANY") {
      return "Persona Jurídica";
    }
    return "";
  };

  if (transaction?.paymentMethodType === "PSE") {
    return (
      <Fragment>
        <TransactionRow label="Estado de la transacción:" value={extraValue} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Descripción del pago:" value={transaction?.paymentMethod?.paymentDescription} thirdPartyCheckout={isNequi} />
        {transaction?.paymentMethod?.extra?.financialInstitutionName && (
          <TransactionRow label="Banco:" value={transaction?.paymentMethod?.extra?.financialInstitutionName} thirdPartyCheckout={isNequi} />
        )}
        <TransactionRow label="Pago efectuado a:" value={merchantInfo} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Fecha de la transaccción:" value={transaction?.paymentMethod?.extra?.bankProcessingDate} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Código Único de Seguimiento (CUS):" value={transaction?.paymentMethod?.extra?.traceabilityCode} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Ticket ID:" value={transaction?.paymentMethod?.extra?.ticketId} thirdPartyCheckout={isNequi} />
      </Fragment>
    );
  } else if (!["BANCOLOMBIA_BNPL", "DAVIPLATA", "SU_PLUS"].includes(transaction?.paymentMethodType)) {
    return (
      <Fragment>
        <TransactionRow label="Pago efectuado a:" value={merchantInfo} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Descripción del pago:" value={transaction?.paymentMethod?.paymentDescription} thirdPartyCheckout={isNequi} />
        <TransactionRow
          label="Tipo de pagador:"
          value={getPayerType()}
          thirdPartyCheckout={isNequi}
        />
        <TransactionRow label="Identificador de la intención de pago:" value={transaction?.paymentMethod?.extra?.externalIdentifier} thirdPartyCheckout={isNequi} />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <TransactionRow label="Pago efectuado a:" value={merchantInfo} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Estado de la transacción:" value={pseStatusDict[transaction.status]} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Tipo de documento pagador:" value={Documents[transaction?.paymentMethod?.userLegalIdType]} thirdPartyCheckout={isNequi} />
        <TransactionRow label="Documento pagador:" value={transaction?.paymentMethod?.userLegalId} thirdPartyCheckout={isNequi} />
      </Fragment>
    );
  }
};


FieldsTransaction.propTypes = {
  thirdPartyCheckout: PropTypes.string,
  transaction: PropTypes.object,
  extraValue: PropTypes.string,
};
