// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import PropTypes from 'prop-types';
import style from './DaviplataSummary.sass';
import logoDaviplata from '../../assets/original-icons/daviplata-logo.svg';
import PaymentSummaryCommon from '../PaymentSummaryCommon/PaymentSummaryCommon';

const DaviplataSummary = ({ transaction, thirdPartyCheckout }) => {
  return (
    <PaymentSummaryCommon
      transaction={transaction}
      thirdPartyCheckout={thirdPartyCheckout}
      methodLabel="Daviplata"
      logoSrc={logoDaviplata}
      altText="Daviplata"
      wrapperClassName={style.daviplataPaymentMethodWrapper}
      logoClassName={style.theDaviplataLogo}
    />
  );
};

DaviplataSummary.propTypes = {
  transaction: PropTypes.object.isRequired,
  thirdPartyCheckout: PropTypes.any,
};

export default DaviplataSummary;
