// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import PropTypes from 'prop-types';
import style from './SuPlusPaySummary.sass';
import logoSuPlusPay from '../../assets/original-icons/su-plus-pay-logo.svg';
import PaymentSummaryCommon from '../PaymentSummaryCommon/PaymentSummaryCommon';

const SuPlusPaySummary = ({ transaction, thirdPartyCheckout }) => {
  return (
    <PaymentSummaryCommon
      transaction={transaction}
      thirdPartyCheckout={thirdPartyCheckout}
      methodLabel="SU+ Pay"
      logoSrc={logoSuPlusPay}
      altText="SU+ Pay"
      wrapperClassName={style.suPlusPayPaymentMethodWrapper}
      logoClassName={style.suPlusPayLogo}
    />
  );
};

SuPlusPaySummary.propTypes = {
  transaction: PropTypes.object.isRequired,
  thirdPartyCheckout: PropTypes.any,
};

export default SuPlusPaySummary;
