import { h } from "preact";
import style from "./RetryMessage.scss"
import retryIconTitle from "../../assets/retryIcon.png"
import PropTypes from "prop-types";

export const RetryMessage = ( props ) => {
  return (
    <div>
      <div className={style.retryInformationTitle}>
        <img alt='iconRetryTitle' src={retryIconTitle} />
        <h2 className={`${props.useNequiStyle && style["retryTitle-nequi"]}`}>Intenta realizar el pago nuevamente</h2>
      </div>
      <div className={style.retryInformation}>
        <p>
          Parece que ha habido un problema al procesar tu pago.
          <br />
          <strong>
            Te recomendamos intentar realizar el pago nuevamente para completar
            tu compra
          </strong>
        </p>
      </div>
    </div>
  )
}

RetryMessage.propTypes = {
  useNequiStyle: PropTypes.bool
}
