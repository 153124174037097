import { h, Component } from "preact"
import style from "./PricePanel.sass"
import { formatPrice, formatCurrency } from "../Formatter/Formatter"
import { isNequiThirdPartyCheckout } from '../../utils/helpers'
import PropTypes from "prop-types";

export default class PricePanel extends Component {
  constructor() {
    super()
    this.state = {
      isHelpOpen: false,
    }
  }

  toggleHelp = ( ) => {
    this.setState({ isHelpOpen: !this.state.isHelpOpen })
  }

  render() {
    return (this.renderPricePanel())
  }

  renderPricePanel = () => {
    const { isHelpOpen } = this.state
    const { $state } = this.props.store
    const { transaction, thirdPartyCheckout } = $state
    const { merchant, currency, amountInCents, reference, transactionTaxes } = transaction

    let name = null
    let description = null
    let imageUrl = null
    let taxes = transactionTaxes

    if ( $state.paymentLink !== null && $state.paymentLink !== undefined ) {
      name = $state.paymentLink.name
      description = $state.paymentLink.description
      imageUrl = $state.paymentLink.imageUrl
      taxes = $state.paymentLink.taxes
    }
    const formattedCurrency = currency && formatCurrency(currency)
    const formattedAmount = amountInCents && formatPrice(amountInCents)

    return (
      <div className={style.pricePanel}>
        <div className={style.paymentDetails}>
          <div className={style.nameWrapper}>
            { imageUrl &&
              <div className={style.imageWrapper}>
                <div style={{ backgroundImage: `url(${imageUrl})` }} className={style.image}></div>
              </div>
            }
            <div className={`${style.itemName} ${ !imageUrl ? style.left : style.centered }`}>
              <div className={style.typeAndHelpWrapper}>
                { ( typeof name === 'string') &&
                  <span className={style.textBreak}>{ name }</span>
                }
                <button className={`${style.helpMe} ${isHelpOpen ? style.isOpen : '' } ${isNequiThirdPartyCheckout(thirdPartyCheckout) ? style["helpMe-nequi"] : ''}`} onClick={this.toggleHelp}>
                  { !isHelpOpen && <span>¿Necesitas ayuda</span> }
                  { '?' }
                </button>
              </div>
              { formattedAmount &&
                <div className={style.priceWrapper}>
                  <div className={`${style.amountToPay} ${ amountInCents.toString().length > 9 ? style.longNumber : ''} ${isNequiThirdPartyCheckout(thirdPartyCheckout) ? style["amountToPay-nequi"] : ''}`}><strong>{formattedAmount}</strong><div className={`${style.currencyLabel} ${isNequiThirdPartyCheckout(thirdPartyCheckout) ? style["currencyLabel-nequi"] : ''}`}>{formattedCurrency}</div></div>
                </div>
              }
              { taxes && taxes.length > 0 &&
                <div className={style.taxInfo}>
                  <ul>
                    {taxes.map((tax, index) =>{
                      return (
                        <li key={index}>{typeLabels[tax.type]} {getAndFormatTax(tax)}</li>
                      )
                    })}
                  </ul>
                  <p>* Los impuestos se incluyen en el monto total</p>
                </div>
              }
              { imageUrl &&
                <div className={style.imageWrapperDesktop}>
                  <div style={{ backgroundImage: `url('${imageUrl}')` }} className={style.image}></div>
                </div>
              }
              { typeof description === 'string' &&
                <div className={`${style.productDescription} ${style.textBreak}`}>{ description }</div>
              }

            </div>
          </div>
          { isHelpOpen &&
            <div className={style.helpWrapper}>
              <div className={style.helpDialog}>
                { (description || imageUrl || name) &&
                  <div className={style.productInfoMobile}>
                    { name && (
                      <div className={style.nameMobile}>{ name }</div>
                    )}
                    { imageUrl && (
                      <div style={{ backgroundImage: `url(${imageUrl})` }} className={style.imageMobile}></div>
                    )}
                    { description && (
                      <div className={style.descriptionMobile}>{ description }</div>
                    )}
                  </div>
                }
                <p>Si tienes preguntas o inquietudes, comunícate con el comercio en el teléfono o email mostrados más abajo:</p>
                <div className={style.property}>
                  <span>Referencia de pago</span>
                  <strong className={style.refText}>{ reference }</strong>
                </div>
                { taxes && taxes.length > 0 &&
                  <div className={style.property}>
                    <span>Información de impuestos</span>
                    <ul>
                      {taxes.map((tax, index) =>{
                        return (
                          <li key={index}>{typeLabels[tax.type]} {getAndFormatTax(tax)}</li>
                        )
                      })}
                    </ul>
                  </div>
                }
                <div className={style.property}>
                  <span>Comercio</span>
                  <strong>{merchant.legalName} - {merchant.legalIdType} {merchant.legalId}</strong>
                </div>
                { (merchant.phoneNumber || merchant.contactName) && (
                  <div className={style.property}>
                    <span>Datos de contacto</span>
                    <strong>{ merchant.contactName } - <a href={`tel:${merchant.phoneNumber}`}>{ merchant.phoneNumber }</a></strong>
                  </div>
                )}
                <div className={style.property}>
                  <span>Email de contacto</span>
                  <strong><a href={`mailto:${merchant.email}`}>{ merchant.email }</a></strong>
                </div>
                <div className={style.close}>
                  <button
                    className={`${style.btn} ${isNequiThirdPartyCheckout(thirdPartyCheckout) ? "button-nequi" : ""}`}
                    onClick={this.toggleHelp}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

PricePanel.propTypes = {
  store: PropTypes.shape({
    $state: PropTypes.shape({
      transaction: PropTypes.shape({
        merchant: PropTypes.shape({
          logoUrl: PropTypes.string,
          name: PropTypes.string
        }),
        currency: PropTypes.string,
        amountInCents: PropTypes.number,
        reference: PropTypes.string,
        transactionTaxes: PropTypes.array
      }),
      thirdPartyCheckout: PropTypes.string
    })
  })
}
