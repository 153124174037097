import { h, Component } from "preact"
import style from "./Confirmation.sass"
import { getURLSearchParams } from '../Utils/Utils'
import PropTypes from 'prop-types';
import { validateRetry } from '../../utils/helpers'
import { Loading } from "../Loading/Loading"
import Result from "../Result/Result"


const configRetryGlobal = {
  isRetryEnabled: false,
  paymentMethodsAllowed: [
    "SU_PLUS",
    "DAVIPLATA",
    "BANCOLOMBIA_BNPL",
    "BANK_ACCOUNT",
    "CARD_TTP",
    "BANCOLOMBIA_QR",
    "BANCOLOMBIA",
    "PSE",
    "BANCOLOMBIA_TRANSFER",
    "NEQUI",
    "CARD"
  ],
  retryTimeoutInSeconds: "180"
}

class Confirmation extends Component {
  constructor() {
    super()
    this.bodyRetryRef = null
    this.state = {
      error: null,
      loaded: false,
      id: null,
      env: null,
      isBodyRetryEnabled: false
    }
  }

  componentDidMount() {
    const { store } = this.props
    const urlParams = getURLSearchParams(window.location.search)
    let id = urlParams.id
    let env = urlParams.env
    this.setState({ id, env })
    store.$actions.setEnv(env)
    if (id) {
      store.$actions.checkTransactionForFinalStatus(id, this.finishedChecking, this.failedChecking, 6)
    } else {
      this.setState({ error: 'No hay un ID para verificar la transacción' })
    }
  }

  componentDidUpdate() { }

  failedChecking = (error) => {
    this.setState({ error: error })
  }

  finishedChecking = async (transaction) => {
    const { store } = this.props
    if (transaction.paymentMethod.extra && transaction.paymentMethod.extra.parentTransactionId) {
      store.$operations.getTransactionByNumber(transaction.paymentMethod.extra.parentTransactionId).then(res => {
        const transactionWithParent = Object.assign({}, store.$state.transaction, { parentTransaction: res.data })
        store.$actions.setTransaction(transactionWithParent)
        this.setState({ loaded: true })
      }).catch(error => console.error(error.json.error.reason))
    } else {
      if (validateRetry(transaction)) {
        let configRetry;
        try {
          const res = await store.$operations.getRetryTransactionConfig(transaction?.merchant?.publicKey);
          configRetry = res.data;
        } catch (error) {
          configRetry = configRetryGlobal;
        }

        let nTransaction = {...transaction, ...{expirationTimeRetry: new Date(new Date(transaction.finalizedAt).getTime() + (configRetry?.retryTimeoutInSeconds * 1000)).toISOString()}};
        store.$actions.setTransaction(nTransaction)
      }

      this.setState({ loaded: true })
    }
  }

  render(props) {
    const { store } = this.props
    const { $state } = store

    if (($state.loaders && $state.loaders.checkingTransaction) || this.state.error) {
      return <Loading isSandbox={store.$actions.isSandbox()} error={this.state.error} thirdPartyCheckout={$state.thirdPartyCheckout} />
    } else if (this.state.loaded && $state.transaction) {
      return <Result store={store} />
    } else if ($state.loaders.redirecting) {
      return (
        <div class={style.redirecting}>Estás siendo redirigido a la página del comercio.</div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

Confirmation.propTypes = {
  store: PropTypes.shape({
    $actions: PropTypes.shape({
      isSandbox: PropTypes.func,
    }),
    $state: PropTypes.shape({
      thirdPartyCheckout: PropTypes.string,
      transaction: PropTypes.shape({
        paymentMethod: PropTypes.shape({
          extra: PropTypes.shape({
            remainingAmountInCents: PropTypes.number,
            traceabilityCode: PropTypes.string,
            externalIdentifier: PropTypes.string,
            financialInstitutionName: PropTypes.string,
            bankProcessingDate: PropTypes.string,
            ticketId: PropTypes.string,
          }),
        }),
        parentTransaction: PropTypes.shape({
          paymentMethod: PropTypes.shape({
            extra: PropTypes.object,
          }),
        }),
        paymentLinkId: PropTypes.string,
        signatureIntegrity: PropTypes.string,
      }),
    }),
    $operations: PropTypes.shape({
      getRetryTransactionConfig: PropTypes.func,
    }),
  }),
};

export default Confirmation
