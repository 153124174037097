// eslint-disable-next-line no-unused-vars
import { h, Fragment } from 'preact';
import PropTypes from 'prop-types';
import Documents from '../Utils/Documents.json';
import { isNequiThirdPartyCheckout } from '../../utils/helpers';
import style from './PaymentSummaryCommon.scss';

const statusLabels = {
  PENDING: 'Pendiente',
  ERROR: 'Fallida',
  DECLINED: 'Rechazada',
  APPROVED: 'Aprobada',
};

const PaymentSummaryCommon = ({
  transaction,
  thirdPartyCheckout,
  methodLabel,       
  logoSrc,           
  altText,           
  wrapperClassName,  
  logoClassName,     
  additionalRows = [],
}) => {
  const { merchant, paymentMethod, status, statusMessage } = transaction || {};
  const isNequi = isNequiThirdPartyCheckout(thirdPartyCheckout);

  const statusText = statusLabels[status] || status;
  const statusClass = status ? status.toLowerCase() : '';

  const baseRows = [
    {
      fullColSpan: true,
      className: `${style.subSection} ${style[statusClass]} ${isNequi ? style['subSection-nequi'] : ''}`,
      label: methodLabel,
    },
    {
      label: 'Pago efectuado a:',
      value: merchant
        ? `${merchant.name} - (${merchant.legalName} ${merchant.legalIdType} ${merchant.legalId})`
        : '',
    },
    {
      label: 'Estado de la transacción:',
      value: statusText,
    },
    {
      label: 'Tipo de documento pagador:',
      value: paymentMethod ? Documents[paymentMethod.userLegalIdType] : '',
    },
    {
      label: 'Documento pagador:',
      value: paymentMethod ? paymentMethod.userLegalId : '',
    },
  ];

  if (statusMessage) {
    const idxEstado = baseRows.findIndex(
      (row) => row.label === 'Estado de la transacción:'
    );
    if (idxEstado >= 0) {
      baseRows.splice(idxEstado + 1, 0, {
        label: 'Mensaje del operador:',
        value: statusMessage,
      });
    }
  }

  const rows = [...baseRows, ...additionalRows];

  return (
    <div className={wrapperClassName}>
      <div className={style.paidWithContainer}>
        <div className={style.paidWith}>Pago efectuado con</div>
        <img src={logoSrc} className={logoClassName} alt={altText} />
      </div>
      <table className={style.trxInfo} cellPadding="5">
        <tbody>
          {rows.map((row) => (
            <tr key={row.label} className={isNequi ? style['row-nequi'] : ''}>
              {row.fullColSpan ? (
                <td
                  colSpan="2"
                  className={`${row.className} ${
                    isNequi ? style['table-data-nequi'] : ''
                  }`}
                >
                  {row.label}
                </td>
              ) : (
                <Fragment>
                  <td
                    className={`${style.property} ${
                      isNequi ? `${style['table-data-nequi']} ${style['property-nequi']}` : ''
                    }`}
                  >
                    {row.label}
                  </td>
                  <td className={isNequi ? `${style['table-data-nequi']} ${style['value-nequi']}` : ''}>
                    {row.value}
                  </td>
                </Fragment>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PaymentSummaryCommon.propTypes = {
  transaction: PropTypes.shape({
    merchant: PropTypes.shape({
      name: PropTypes.string,
      legalName: PropTypes.string,
      legalIdType: PropTypes.string,
      legalId: PropTypes.string,
    }),
    paymentMethod: PropTypes.shape({
      userLegalIdType: PropTypes.string,
      userLegalId: PropTypes.string,
    }),
    status: PropTypes.string,
    statusMessage: PropTypes.string,
  }),
  thirdPartyCheckout: PropTypes.any,
  methodLabel: PropTypes.string,
  logoSrc: PropTypes.string,
  altText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  logoClassName: PropTypes.string,
  additionalRows: PropTypes.arrayOf(
    PropTypes.shape({
      fullColSpan: PropTypes.bool,
      className: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

export default PaymentSummaryCommon;
